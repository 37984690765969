import React, { useState, useEffect } from "react";
import "./App.css";

const Countdown = ({ targetDate, onCountdownEnd }) => {
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    const targetTime = new Date(targetDate).getTime();
    const difference = targetTime - now;

    if (difference <= 0) return null; // Countdown finished
    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((difference % (1000 * 60)) / 1000),
    };
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    if (!timeRemaining) {
      onCountdownEnd(); // Trigger when countdown finishes
      return;
    }

    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
  }, [timeRemaining, onCountdownEnd]);

  if (!timeRemaining) return null; // Don't render if countdown is finished

  return (
    <div className="countdown-container">
      <h1 className="countdown-title">Countdown to Anis's Birthday! 🎊🥳🎀</h1>
      <div className="countdown-timer">
        {Object.entries(timeRemaining).map(([key, value]) => (
          <div className="countdown-box" key={key}>
            <span>{value}</span>
            <p>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const SelectionScreen = () => {
  return (
    <div className="selection-container">
      <button
        className="selection-button unlocked"
        onClick={() => (window.location.href = "https://chat.anis.syafiqlim.my")}
      >
        Chatbot untuk Anis (Tekan sini!)
      </button>
      {[...Array(5)].map((_, idx) => (
        <button key={idx} className="selection-button locked">
          Akan datang??? hehe
        </button>
      ))}
    </div>
  );
};

const App = () => {
  const targetDate = "2024-12-16T23:59:59+08:00";
  const [showSelection, setShowSelection] = useState(false);

  useEffect(() => {
    const now = new Date().getTime();
    const targetTime = new Date(targetDate).getTime();
    const countdownFinished = now >= targetTime;

    if (countdownFinished) {
      setShowSelection(true); // Show selection if countdown is finished
      localStorage.setItem("countdownFinished", "true");
    }
  }, [targetDate]);

  const handleCountdownEnd = () => {
    localStorage.setItem("countdownFinished", "true");
    setShowSelection(true);
  };

  return (
    <div className="app">
      {!showSelection ? (
        <Countdown targetDate={targetDate} onCountdownEnd={handleCountdownEnd} />
      ) : (
        <SelectionScreen />
      )}
    </div>
  );
};

export default App;
